import { Container, Typography } from "@mui/material";

function Monitor() {
  return (
    <Container sx={(theme) => ({ paddingY: theme.spacing(3) })}>
      <Typography variant="overline" tag="p" gutterBottom>
        Coming Soon
      </Typography>
      <Typography variant="h4" tag="div">
        Monitor
      </Typography>
    </Container>
  );
}

export default Monitor;
