/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_cognito_identity_pool_id": "eu-west-3:e2ea60bd-c1b2-4542-9c89-76dd7674abdb",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_ARNueZzHq",
    "aws_user_pools_web_client_id": "2tblvt9ba6m2o2c33nmcg1gvcc",
    "oauth": {
        "domain": "xtun-oauth-staging.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.xtun.io/,https://devapp.xtun.io/,https://192.168.0.58:5050/",
        "redirectSignOut": "https://app.xtun.io/,https://devapp.xtun.io/,https://192.168.0.58:5050/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ajiqlxh5ybbc5gqkh5yk4i7f3u.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
