import { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { useParams } from "react-router-dom";
import {
  Paper,
  Box,
  Typography,
  Divider,
  Stack,
  Link,
  Snackbar,
  Alert,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import WebIcon from "@mui/icons-material/Web";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Logo from "../../../../assets/logo.svg";

import { FullscreenLoader } from "../../../../components";
import { StarsWidget, SentimentWidget, NPSWidget } from "./widgets";

const getPoll = /* GraphQL */ `
  query GetPoll($id: ID!) {
    getPoll(id: $id) {
      question
      widget
      createdAt
      Client {
        name
        site
      }
      Service {
        name
      }
    }
  }
`;

const PollWidgets = {
  STARS: StarsWidget,
  SENTIMENT: SentimentWidget,
  NPS: NPSWidget,
};

function FeedbackModule() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [poll, setPoll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [vote, setVote] = useState(0);
  const [hover, setHover] = useState(-1);

  const { id } = useParams();
  const Widget = PollWidgets[poll?.widget];

  const handleVote = (_vote) => {
    setVote(_vote);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleVotingUndo = () => {
    setHover(-1);
    setVote(0);
    handleSnackbarClose();
  };

  useEffect(() => {
    API.graphql({
      query: getPoll,
      authMode: "AWS_IAM",
      variables: {
        id,
      },
    }).then(({ data: { getPoll: _poll } }) => {
      setPoll(_poll);
      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return <FullscreenLoader caption="Loading poll details..." />;
  }

  return (
    <>
      <Paper
        elevation={isMobile ? 0 : 1}
        sx={(theme) => ({
          marginY: theme.spacing(5),
          marginX: "auto",
          width: "100%",
          maxWidth: "640px",
          padding: theme.spacing(4),
          [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
          },
        })}
      >
        <Stack spacing={4}>
          <Stack spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <BusinessCenterIcon />
              <Typography variant="subtitle2">{poll.Service.name}</Typography>
            </Stack>
            <Typography variant="h3">{poll.question}</Typography>
          </Stack>
          <Box justifyContent="center" display="flex">
            <Widget
              onVote={handleVote}
              onHover={setHover}
              value={vote}
              hover={hover}
            />
          </Box>
          <Paper
            elevation={isMobile ? 1 : 0}
            variant={isMobile ? "elevation" : "outlined"}
            sx={(theme) => ({
              paddingY: theme.spacing(2),
              paddingX: theme.spacing(1),
            })}
          >
            <Stack direction="row" spacing={2}>
              <img
                src={Logo}
                alt={`${poll.Client.name}'s Logo`}
                width={isMobile ? 100 : 140}
              />
              <Divider orientation="vertical" variant="middle" flexItem />
              <Stack spacing={2} justifyContent="space-between">
                <Box>
                  <Typography variant="h5">{poll.Client.name}</Typography>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="caption">
                      Received 2443 ratings
                    </Typography>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Typography variant="caption">Good</Typography>
                  </Stack>
                </Box>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <WebIcon />
                  <Link href={poll.Client.site} target="_blank">
                    {poll.Client.site
                      .replace("https://", "")
                      .replace("http://", "")}
                  </Link>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
          <Stack spacing={2}>
            <Link href="https://xtun.io/" target="_blank">
              How the rating system works?
            </Link>
            <Link href="https://xtun.io/" target="_blank">
              Why I'm seeing this?
            </Link>
            <Link href="https://xtun.io/" target="_blank">
              What is Xtun? Learn more about us.
            </Link>
          </Stack>
        </Stack>
      </Paper>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
          action={
            <Button color="inherit" size="small" onClick={handleVotingUndo}>
              UNDO
            </Button>
          }
        >
          Thanks! You voted successfully.
        </Alert>
      </Snackbar>
    </>
  );
}

export default FeedbackModule;
