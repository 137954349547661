import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";

import { listClients } from "../graphql/queries";

export const ClientContext = React.createContext();

function ClientContextProvider(props) {
  const [loading, setLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [active, setActive] = useState(undefined);

  const fetchClients = async () => {
    const {
      data: { listClients: clients },
    } = await API.graphql(graphqlOperation(listClients));
    setClients(clients.items);
    setLoading(false);
  };

  const push = (client) => {
    setClients((_clients) => {
      const clients = [..._clients];
      clients.push(client);

      return clients;
    });
  };

  // init
  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <ClientContext.Provider
      value={{
        clients,
        active,
        setActive,
        push,
        loading,
      }}
    >
      {props.children}
    </ClientContext.Provider>
  );
}

export default ClientContextProvider;
