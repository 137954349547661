import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import WebModule from "./modules/Web/WebModule";
import AuthModule from "./modules/Auth/AuthModule";
import { AmplifyAuthenticator } from "./components";
import { ClientContextProvider } from "./context";
import reportWebVitals from "./reportWebVitals";

import "@aws-amplify/ui-react/styles.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./index.css";

import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const theme = createTheme({
  palette: {
    primary: {
      main: "#24354F",
    },
    secondary: {
      main: "#5BB8DF",
    },
  },
});

const isApp = window.location.origin === process.env.REACT_APP_APP_URL;

ReactDOM.render(
  <React.StrictMode>
    <AmplifyProvider>
      <ThemeProvider theme={theme}>
        {isApp ? (
          <AmplifyAuthenticator>
            <ClientContextProvider>
              <AuthModule />
            </ClientContextProvider>
          </AmplifyAuthenticator>
        ) : (
          <WebModule />
        )}
      </ThemeProvider>
    </AmplifyProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
