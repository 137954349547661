import { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import QRCode from "react-qr-code";
import MuiPhoneNumber from "material-ui-phone-number";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Stack,
  Container,
  Typography,
  Grid,
  Skeleton,
  Paper,
  Box,
  InputBase,
  IconButton,
  Divider,
  Tooltip,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import { useParams } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useClipboard from "react-use-clipboard";

import { getPoll } from "../../../../graphql/queries";
import { AppConstants } from "../constants";

function Poll() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [poll, setPoll] = useState(null);
  const [link, setLink] = useState(null);
  const [isCopied, setCopied] = useClipboard(link, {
    successDuration: 1500,
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setTouched,
    setFieldValue,
    values,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      via: "SMS",
      receiver: "",
    },
    validationSchema: Yup.object({
      via: Yup.string()
        .oneOf(
          ["SMS", "EMAIL", "VIBER", "WHATSAPP", "TELEGRAM"],
          "Send via must be one of SMS, Email, Viber, WhatsApp, Telegram.",
        )
        .required("Required"),
      receiver: Yup.string().required("Required"),
    }),
    onSubmit: async ({ via, receiver }, { setSubmitting }) => {
      console.log(via, receiver);
      setSubmitting(false);
    },
  });

  useEffect(() => {
    setLoading(true);
    API.graphql(graphqlOperation(getPoll, { id })).then(
      ({ data: { getPoll: _poll } }) => {
        setPoll(_poll);
        setLink(
          `${process.env.REACT_APP_BASE_URL}/${_poll.links.items[0]?.id}`,
        );
        setLoading(false);
      },
    );
  }, [id]);

  return (
    <Container
      sx={(theme) => ({
        paddingY: theme.spacing(3),
      })}
    >
      <Typography variant="h4">{poll?.question || "Poll"}</Typography>
      <Box>
        <Typography variant="overline">
          SERVICE: {poll?.Service.name || ""}
        </Typography>
      </Box>
      <Box>
        <Typography variant="overline">
          WIDGET: {AppConstants.pollWidget2Name[poll?.widget] || ""}
        </Typography>
      </Box>
      <Paper
        variant="outlined"
        sx={(theme) => ({
          paddingY: theme.spacing(3),
          marginBottom: theme.spacing(2),
        })}
      >
        <Container>
          {loading ? (
            <Grid container spacing="24">
              <Grid item xs={12} md={6}>
                <Skeleton variant="text" width="66%" height={45} />
                <Skeleton variant="text" width="44%" height={30} />
                <Skeleton variant="rectangular" height={50} />
                <Box height={30}></Box>
                <Skeleton variant="text" width="66%" height={45} />
                <Skeleton variant="rectangular" height={50} />
                <Box height={20}></Box>
                <Skeleton variant="rectangular" height={50} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Skeleton
                  variant="rectangular"
                  width={200}
                  height={200}
                  sx={{ marginX: "auto" }}
                />
                <Box height={30}></Box>
                <Skeleton variant="text" width="66%" height={45} />
                <Skeleton variant="rectangular" height={50} />
                <Box height={30}></Box>
                <Skeleton variant="text" width="66%" height={45} />
                <Skeleton variant="rectangular" height={50} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing="24">
              <Grid item xs={12} md={6}>
                <Typography variant="h4">Share via Message</Typography>
                <form onSubmit={handleSubmit} noValidate>
                  <Stack spacing={2}>
                    <FormControl error={touched.via && Boolean(errors.via)}>
                      <FormLabel component="legend">Send poll via:</FormLabel>
                      <Select
                        required
                        name="via"
                        value={values.via}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onFocus={() =>
                          setTouched({ ...touched, via: true }, false)
                        }
                      >
                        <MenuItem value="SMS">SMS</MenuItem>
                        <MenuItem value="EMAIL">Email</MenuItem>
                        <MenuItem value="VIBER">Viber</MenuItem>
                        <MenuItem value="WHATSAPP">WhatsApp</MenuItem>
                        <MenuItem value="TELEGRAM">Telegram</MenuItem>
                      </Select>
                      <FormHelperText>
                        {touched.via && errors.via}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <FormLabel component="legend">
                        Receiver Phone Number:
                      </FormLabel>
                      <MuiPhoneNumber
                        name="receiver"
                        variant="outlined"
                        defaultCountry={"bg"}
                        onlyCountries={["bg"]}
                        value={values.receiver}
                        onChange={(val) => setFieldValue("receiver", val)}
                        onBlur={handleBlur}
                        onFocus={() =>
                          setTouched({ ...touched, receiver: true }, false)
                        }
                      />
                    </FormControl>
                    {values.via && values.receiver && (
                      <LoadingButton
                        loading={isSubmitting}
                        variant="outlined"
                        type="submit"
                      >
                        Send &rarr;
                      </LoadingButton>
                    )}
                  </Stack>
                </form>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={4}>
                  <Typography variant="h4">Share via QR</Typography>
                  {link && (
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <QRCode size={200} value={link} />
                    </Box>
                  )}
                  <Typography variant="h4">Share via Link</Typography>
                  {link && (
                    <FormControl>
                      <Paper
                        component="form"
                        elevation={1}
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InputBase
                          id="poll-link"
                          sx={{ ml: 1, flex: 1 }}
                          value={link}
                          readOnly
                        />
                        <Tooltip title="Open voting page in new tab">
                          <IconButton
                            href={link}
                            target="_blank"
                            color="secondary"
                            sx={{ p: "10px" }}
                            aria-label="Open"
                          >
                            <OpenInNewIcon />
                          </IconButton>
                        </Tooltip>
                        <Divider
                          sx={{ height: 28, m: 0.5 }}
                          orientation="vertical"
                        />
                        <Tooltip title="Copy voting link to clipboard">
                          <IconButton
                            onClick={() => {
                              setCopied(true);
                            }}
                            color="primary"
                            sx={{ p: "10px" }}
                            aria-label="Open"
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </Paper>
                      {isCopied && (
                        <FormHelperText id="poll-link-helper">
                          Copied to clipboard
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                </Stack>
              </Grid>
            </Grid>
          )}
        </Container>
      </Paper>
    </Container>
  );
}

export default Poll;
