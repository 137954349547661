import React, { useContext } from "react";

import { ClientContext } from "../../context";
import { FullscreenLoader } from "../../components";
import AppModule from "./App/AppModule";
import OnboardingModule from "./Onboarding/OnboardingModule";

function AuthModule() {
  const { loading: loadingClient, active: activeClient } =
    useContext(ClientContext);
  const isOnboardingDone = !!activeClient;

  if (loadingClient) {
    return <FullscreenLoader caption="Loading your profile data..." />;
  }

  return isOnboardingDone ? <AppModule /> : <OnboardingModule />;
}

export default AuthModule;
