import { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  Box,
  AppBar,
  SwipeableDrawer,
  Toolbar,
  IconButton,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Menu,
  MenuItem,
  Fab,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import { Link, Outlet } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TerminalIcon from "@mui/icons-material/Terminal";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import MoreIcon from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/material/styles";

import Logo from "../../../../assets/logo.svg";

const miniDrawerWidth = 56;
const drawerWidth = 360;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme }) => ({
    padding: 0,
    width: "100%",
    height: "100%",
    overflowX: "hidden",
    overflowY: "scroll",

    [theme.breakpoints.down("sm")]: {
      "&:after": {
        display: "block",
        ...theme.mixins.toolbar,
        content: '""',
      },
    },

    [theme.breakpoints.between("sm", "lg")]: {
      "&:before": {
        display: "block",
        ...theme.mixins.toolbar,
        content: '""',
      },
    },

    [theme.breakpoints.up("lg")]: {
      "&:before": {
        display: "block",
        ...theme.mixins.toolbar,
        content: '""',
      },
    },
  }),
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-start",
  },
  justifyContent: "flex-end",
}));

// at this stage we have 'logged in user' and 'activeClient'
function AppLayout({ children }) {
  const authenticator = useAuthenticator();
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(null);
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  // const {
  //   user: {
  //     attributes: { name },
  //   },
  // } = authenticator;
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAppMenuOpen = (event, menu) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(menu);
  };

  const handleAppMenuClose = () => {
    setAnchorEl(null);
    setOpenMenu(null);
  };

  const signOutHandler = () => {
    authenticator.signOut();
    navigate("/");
  };

  const ListItems = ({ items }) =>
    items.map(({ path, icon, primary, secondary }, key) => (
      <ListItem
        key={key}
        button
        selected={location.pathname === path}
        component={Link}
        to={path}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    ));

  const AppBarMenu = (props) => {
    return (
      <Menu
        id={props.id}
        aria-controls={props.ariaControls}
        anchorEl={anchorEl}
        open={openMenu === props.id}
        onClose={handleAppMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: (theme) => ({
            width: "100%",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            [theme.breakpoints.up("sm")]: {
              width: "240px",
              mt: 2,
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          }),
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {props.children}
      </Menu>
    );
  };

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${miniDrawerWidth}px + 1px)`,
  });

  useEffect(() => {
    if (isMobile || isTablet) {
      handleDrawerClose();
    }
  }, [location, isMobile, isTablet]);

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <AppBar
        position="fixed"
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            top: "auto",
            bottom: 0,
          },
        })}
        open={open}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}></Box>
          {!isMobile && (
            <Tooltip title="Create ...">
              <IconButton
                id="createMenuTrigger"
                aria-controls="createMenu"
                aria-haspopup="true"
                aria-expanded={true}
                color="inherit"
                onClick={(event) => {
                  handleAppMenuOpen(event, "createMenu");
                }}
              >
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Account settings">
            <IconButton
              id="moreMenuTrigger"
              aria-controls="moreMenu"
              aria-haspopup="true"
              aria-expanded={true}
              color="inherit"
              onClick={(event) => {
                handleAppMenuOpen(event, "moreMenu");
              }}
            >
              <MoreIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <AppBarMenu id="createMenu" ariaControls="createMenuTrigger">
          <MenuItem onClick={() => {}}>
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>New Invite</ListItemText>
          </MenuItem>
          <MenuItem
            component={Link}
            to="/create/poll"
            onClick={handleAppMenuClose}
          >
            <ListItemIcon>
              <SendIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>New Poll</ListItemText>
          </MenuItem>
        </AppBarMenu>
        <AppBarMenu id="moreMenu" ariaControls="moreMenuTrigger">
          <MenuItem onClick={() => {}}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {}}>
            <ListItemIcon>
              <HelpCenterIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Help Center</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem onClick={signOutHandler}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </MenuItem>
        </AppBarMenu>
      </AppBar>
      <SwipeableDrawer
        sx={(theme) => ({
          width: drawerWidth,
          flexShrink: 0,
          whiteSpace: "nowrap",
          boxSizing: "border-box",

          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            justifyContent: "space-between",
          },

          [theme.breakpoints.down("sm")]: {
            "& .MuiDrawer-paper": {
              width: "85vw",
              maxWidth: drawerWidth,
              flexDirection: "column-reverse",
            },
          },

          [theme.breakpoints.between("sm", "lg")]: {
            ...(open && {
              ...openedMixin(theme),
              "& .MuiDrawer-paper": openedMixin(theme),
            }),
            ...(!open && {
              ...closedMixin(theme),
              "& .MuiDrawer-paper": closedMixin(theme),
            }),
          },

          [theme.breakpoints.up("lg")]: {
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          },
        })}
        variant={isMobile ? `temporary` : `permanent`}
        anchor="left"
        open={open}
        onOpen={() => handleDrawerOpen()}
        onClose={() => handleDrawerClose()}
        disableBackdropTransition={!iOS}
        disableDiscovery={iOS}
      >
        <Box
          sx={(theme) => {
            return {
              display: "flex",
              flexDirection: "column",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column-reverse",
              },
            };
          }}
        >
          {isTablet && (
            <DrawerHeader>
              <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
                {open ? <ChevronLeftIcon /> : <MenuIcon />}
              </IconButton>
            </DrawerHeader>
          )}
          {isMobile && <Divider />}
          <Box>
            {!isTablet || (isTablet && open) ? (
              <Box
                sx={{
                  textAlign: "center",
                  marginY: theme.spacing(2),
                  [theme.breakpoints.up("sm")]: {
                    marginY: theme.spacing(4),
                  },
                }}
              >
                <Link to="/">
                  <img alt="logo" src={Logo} width="200" />
                </Link>
              </Box>
            ) : null}
            <List>
              <ListItems
                items={[
                  {
                    path: "/",
                    icon: <DashboardIcon />,
                    primary: "Assistant",
                    secondary: "Personal Dashboard",
                  },
                  {
                    path: "/terminal",
                    icon: <TerminalIcon />,
                    primary: "Terminal",
                  },
                  {
                    path: "/monitor",
                    icon: <MonitorHeartIcon />,
                    primary: "Monitor",
                  },
                ]}
              />
              <Divider />
              <ListItems
                items={[
                  {
                    path: "/help",
                    icon: <HelpCenterIcon />,
                    primary: "Help Center",
                    secondary: "Frequently Asked Questions",
                  },
                ]}
              />
            </List>
          </Box>
        </Box>
        {!isTablet || (isTablet && open) ? (
          <Card
            sx={(theme) => ({
              margin: theme.spacing(2),
              [theme.breakpoints.up("lg")]: {
                margin: theme.spacing(3),
              },
            })}
          >
            <CardContent>
              <Typography
                fontSize={isDesktop ? `16px` : isTablet ? `14px` : `12px`}
                color="text.secondary"
                gutterBottom
              >
                ACCOUNT CREDITS
              </Typography>
              <Typography
                variant={isDesktop ? `h3` : isTablet ? "h4" : "h4"}
                component="div"
              >
                38
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small">Buy More &rarr;</Button>
            </CardActions>
          </Card>
        ) : null}
      </SwipeableDrawer>
      <Main open={open}>
        <Outlet />
        {/* @TODO add Footer */}
        {["/", "/terminal", "/monitor", "/help"].includes(location.pathname) &&
          isMobile && (
            <Fab
              sx={(theme) => ({
                position: "fixed",
                bottom: `calc(56px + ${theme.spacing(3)})`,
                right: theme.spacing(3),
              })}
              color="primary"
              aria-label="add"
              id="createMenuTrigger"
              aria-controls="createMenu"
              aria-haspopup="true"
              aria-expanded={true}
              onClick={(event) => {
                handleAppMenuOpen(event, "createMenu");
              }}
            >
              <AddIcon />
            </Fab>
          )}
      </Main>
    </Box>
  );
}

export default AppLayout;
