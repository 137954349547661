import { Container, Box, Typography, Stack, Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";

function NotFound() {
  return (
    <Container sx={{ height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Stack spacing={2}>
          <Typography variant="h1">404</Typography>
          <Typography variant="h3">We couldn't find this page.</Typography>
          <Box>
            <Button
              variant="contained"
              href="/"
              size="large"
              startIcon={<HomeIcon />}
            >
              Home
            </Button>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}

export default NotFound;
