import { Stack, Rating, Typography } from "@mui/material";

const labels = {
  1: "Useless",
  2: "Poor",
  3: "Ok",
  4: "Good",
  5: "Excellent",
};

function StarsWidget({ onHover, onVote, value, hover }) {
  const label = labels[hover !== -1 ? hover : value];

  return (
    <Stack spacing={2} alignItems="center">
      <Rating
        readOnly={!!value}
        value={value}
        size="large"
        onChange={(_, _value) => {
          onVote(_value);
        }}
        onChangeActive={(_, _hover) => {
          onHover(_hover);
        }}
        sx={(theme) => ({
          fontSize: theme.spacing(5),
        })}
      />
      {label ? (
        <Typography sx={{ height: "1.2rem" }} variant="overline">
          Your Rating: <strong>{label}</strong>
        </Typography>
      ) : (
        <Typography sx={{ height: "1.2rem" }} variant="subtitle2">
          Please, choose rating.
        </Typography>
      )}
    </Stack>
  );
}

export default StarsWidget;
