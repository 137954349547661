import { useState } from "react";
import {
  Flex,
  Divider,
  Button,
  Heading,
  TextField,
  View,
} from "@aws-amplify/ui-react";
import { API, graphqlOperation } from "aws-amplify";

import { createClient } from "../../../../graphql/mutations";

function ClientCreator({ onCreate }) {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [site, setSite] = useState("");
  const [desc, setDesc] = useState("");
  const [vat, setVat] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    API.graphql(
      graphqlOperation(createClient, {
        input: {
          name: name,
          desc: desc,
          site: site,
          vat: vat,
        },
      }),
    ).then(({ data: { createClient: client } }) => {
      setLoading(false);
      onCreate(client);
    });
  };

  return (
    <Flex direction="column" margin="1rem auto" maxWidth="480px">
      <Heading level={1} margin="0 0 1rem">
        Create Profile
      </Heading>
      <form onSubmit={submitHandler}>
        <View margin="0 0 2rem">
          <TextField
            label="Client Name *"
            placeholder="Galadriel"
            descriptiveText="Please enter valid client name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </View>
        <View margin="0 0 2rem">
          <TextField
            label="Site"
            placeholder="https://google.com"
            descriptiveText="Please enter your website"
            value={site}
            onChange={(e) => setSite(e.target.value)}
          />
        </View>
        <View margin="0 0 2rem">
          <TextField
            label="Description"
            placeholder="Lorem ipsum dolar sit..."
            descriptiveText="Please enter description"
            isMultiline={true}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </View>
        <View margin="0 0 2rem">
          <TextField
            label="VAT ID"
            placeholder="BG42323409234"
            descriptiveText="Please enter valid VAT number"
            value={vat}
            onChange={(e) => setVat(e.target.value)}
          />
        </View>
        <Divider size="small" margin="0 0 2rem" />
        <Button type="submit" loadingText="Creating..." isLoading={loading}>
          Create
        </Button>
      </form>
    </Flex>
  );
}

export default ClientCreator;
