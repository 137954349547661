import { Container, Typography } from "@mui/material";

function HelpCenter() {
  return (
    <Container sx={(theme) => ({ paddingY: theme.spacing(3) })}>
      <Typography variant="overline" tag="p" gutterBottom>
        Coming Soon
      </Typography>
      <Typography variant="h4" tag="div">
        Help Center
      </Typography>
      <Typography variant="body1" tag="div">
        If you need immediate help please write directly to{" "}
        <a href="mailto:office@xtun.io">office@xtun.io</a>
      </Typography>
    </Container>
  );
}

export default HelpCenter;
