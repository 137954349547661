import { useContext, useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Container,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Rating,
  Typography,
  Chip,
  Autocomplete,
  TextField,
  FormGroup,
  createFilterOptions,
  Paper,
  FormHelperText,
  Grid,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import shortlink from "shortlink";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

import {
  createPoll,
  createLink,
  createService,
} from "../../../../graphql/mutations";
import { getLink, listServices } from "../../../../graphql/queries";
import { ClientContext } from "../../../../context";
import { AppConstants } from "../constants";

function SentimentIconContainer(props) {
  const { value, ...other } = props;

  const icons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon />,
      label: "Very Dissatisfied",
    },
    2: {
      icon: <SentimentSatisfiedIcon />,
      label: "Neutral",
    },
    3: {
      icon: <SentimentVerySatisfiedIcon />,
      label: "Very Satisfied",
    },
  };

  return <span {...other}>{icons[value].icon}</span>;
}

function NPSIconContainer(props) {
  const { value } = props;

  const icons = [];
  for (let i = 0; i <= 10; i++) {
    icons.push({
      icon: <Chip size="small" label={i} />,
      label: i,
    });
  }

  return icons[value].icon;
}

const filter = createFilterOptions();

function CreatePoll() {
  const navigate = useNavigate();
  const { active: activeClient } = useContext(ClientContext);
  const [loadingServices, setLoadingServices] = useState(false);
  const [services, setServices] = useState([]);

  useEffect(() => {
    setLoadingServices(true);
    API.graphql(graphqlOperation(listServices)).then(
      ({ data: { listServices: _services } }) => {
        setServices(_services.items);
        setLoadingServices(false);
      },
    );
  }, []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setTouched,
    values,
    touched,
    errors,
    isSubmitting,
  } = useFormik({
    initialValues: {
      service: null,
      widget: null,
      question: "",
      name: "",
    },
    validationSchema: Yup.object({
      service: Yup.object()
        .shape({ name: Yup.string(), id: Yup.string().nullable(true) })
        .required("Required"),
      widget: Yup.string()
        .oneOf(
          ["STARS", "SENTIMENT", "NPS"],
          "Widget must be one of STARS, SENTIMENT or NPS.",
        )
        .required("Required"),
      question: Yup.string().required("Required"),
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (
      { service, widget, question, name },
      { setSubmitting },
    ) => {
      if (service.id === null) {
        const {
          data: {
            createService: { id: serviceId },
          },
        } = await API.graphql(
          graphqlOperation(createService, {
            input: {
              name: service.name,
            },
          }),
        );
        service.id = serviceId;
      }

      const {
        data: { createPoll: poll },
      } = await API.graphql(
        graphqlOperation(createPoll, {
          input: {
            type: "POLL",
            clientID: activeClient.id,
            serviceID: service.id,
            widget,
            question,
            name,
          },
        }),
      );
      var linkID, link;
      do {
        linkID = shortlink.generate(8);
        const { data } = await API.graphql({
          query: getLink,
          authMode: "AWS_IAM",
          variables: {
            id: linkID,
          },
        });
        link = data.getLink;
      } while (link);

      await API.graphql(
        graphqlOperation(createLink, {
          input: {
            id: linkID,
            subjectType: "POLL",
            subjectId: poll.id,
          },
        }),
      );
      setSubmitting(false);
      navigate(`/poll/${poll.id}`);
    },
  });

  // auto generate a question based on the service and widget
  useEffect(() => {
    if (values.service && values.widget && !touched.question) {
      const widget2Question = {
        STARS: "How do you rate [service]?",
        SENTIMENT: "How do you feel about [service]?",
        NPS: `How likely are you to recommend [service] on a scale from 1 to 10?`,
      };
      const question = widget2Question[values.widget].replace(
        "[service]",
        values.service.name.toLowerCase(),
      );
      setFieldValue("question", question);
    }
  }, [values, touched, setFieldValue]);

  // auto generate a name based on the service, widget and question
  useEffect(() => {
    if (values.service && values.widget && values.question && !touched.name) {
      const widget = AppConstants.pollWidget2Name[values.widget];
      setFieldValue(
        "name",
        `${widget} / ${values.service.name} / ${values.question}`,
      );
    }
  }, [values, touched, setFieldValue]);

  return (
    <Container>
      <Typography
        variant="h3"
        component="div"
        sx={(theme) => ({
          marginTop: theme.spacing(3),
          marginBottom: theme.spacing(1),
        })}
      >
        Create Poll
      </Typography>
      <form onSubmit={handleSubmit} noValidate>
        <Paper
          elevation={1}
          sx={(theme) => ({
            paddingY: theme.spacing(3),
            marginBottom: theme.spacing(2),
          })}
        >
          <Grid container spacing="16">
            <Grid item xs={12} md={6}>
              <Container>
                <Typography variant="h6" gutterBottom component="div">
                  Tell us a little bit about your poll:
                </Typography>
                <FormGroup
                  sx={(theme) => ({
                    marginBottom: theme.spacing(2),
                  })}
                >
                  <FormLabel component="legend">
                    Which service to rate
                  </FormLabel>
                  <Autocomplete
                    loading={loadingServices}
                    loadingText="Loading Services..."
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      const isExisting = options.some(
                        (option) =>
                          inputValue.trim().toLowerCase() ===
                          option.name.toLowerCase(),
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    options={services}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }

                      return option.name;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        margin="dense"
                        label="Service *"
                        error={touched.service && Boolean(errors.service)}
                      />
                    )}
                    freeSolo
                    id="service"
                    value={values.service}
                    onChange={(_, newValue) => {
                      if (typeof newValue === "string") {
                        const serviceIdx = services.findIndex(
                          (option) =>
                            newValue.trim().toLowerCase() ===
                            option.name.toLowerCase(),
                        );
                        const service =
                          serviceIdx > -1
                            ? services[serviceIdx]
                            : { name: newValue, id: null };
                        setFieldValue("service", service);
                      } else if (newValue && newValue.inputValue) {
                        setFieldValue("service", {
                          name: newValue.inputValue,
                          id: null,
                        });
                      } else {
                        setFieldValue("service", newValue);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <FormControl
                  component="fieldset"
                  sx={(theme) => ({ marginBottom: theme.spacing(2) })}
                  error={touched.widget && Boolean(errors.widget)}
                  onBlur={handleBlur}
                >
                  <FormLabel component="legend">
                    Which widget to use *
                  </FormLabel>
                  <RadioGroup
                    aria-label="Widget"
                    name="widget"
                    value={values.widget}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="STARS"
                      control={<Radio />}
                      label={<Rating precision={0.5} value={2.5} readOnly />}
                      disableTypography
                    />
                    {values.widget === "STARS" && (
                      <FormHelperText>Measures Rating</FormHelperText>
                    )}
                    <FormControlLabel
                      value="SENTIMENT"
                      control={<Radio />}
                      label={
                        <Rating
                          value={2}
                          IconContainerComponent={SentimentIconContainer}
                          highlightSelectedOnly
                          max={3}
                          readOnly
                        />
                      }
                      disableTypography
                    />
                    {values.widget === "SENTIMENT" && (
                      <FormHelperText>Measures Sentiment</FormHelperText>
                    )}
                    <FormControlLabel
                      value="NPS"
                      control={<Radio />}
                      label={
                        <Rating
                          sx={(theme) => ({
                            gap: theme.spacing(0.5),
                            [theme.breakpoints.down("sm")]: {
                              transform: "scale(0.85)",
                              transformOrigin: "0 50%",
                            },
                          })}
                          value={7}
                          IconContainerComponent={NPSIconContainer}
                          max={10}
                          readOnly
                        />
                      }
                      disableTypography
                    />
                    {values.widget === "NPS" && (
                      <FormHelperText>
                        Measures Net Promoter Score
                      </FormHelperText>
                    )}
                  </RadioGroup>
                  <FormHelperText>
                    {touched.widget && errors.widget}
                  </FormHelperText>
                </FormControl>
                <FormGroup
                  sx={(theme) => ({
                    marginBottom: theme.spacing(2),
                  })}
                >
                  <FormLabel component="legend">Question of the poll</FormLabel>
                  <TextField
                    required
                    id="question"
                    label="Question"
                    variant="outlined"
                    margin="dense"
                    value={values.question}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() =>
                      setTouched({ ...touched, question: true }, false)
                    } // helps prevent auto generation from being triggered on user input
                    error={touched.question && Boolean(errors.question)}
                    helperText={touched.question && errors.question}
                  />
                </FormGroup>
                <FormGroup
                  sx={(theme) => ({
                    marginBottom: theme.spacing(2),
                  })}
                >
                  <FormLabel component="legend">Name your poll</FormLabel>
                  <TextField
                    required
                    id="name"
                    label="Name"
                    variant="outlined"
                    margin="dense"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={() =>
                      setTouched({ ...touched, name: true }, false)
                    } // helps prevent auto generation from being triggered on user input
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </FormGroup>
              </Container>
            </Grid>
            <Grid item xs={12} md={6}>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                Preview
              </Container>
            </Grid>
            {values.service && values.widget && values.question && values.name && (
              <Grid item xs={12}>
                <Container>
                  <FormGroup>
                    <LoadingButton
                      loading={isSubmitting}
                      variant="outlined"
                      type="submit"
                    >
                      Create &rarr;
                    </LoadingButton>
                  </FormGroup>
                </Container>
              </Grid>
            )}
          </Grid>
        </Paper>
      </form>
    </Container>
  );
}

export default CreatePoll;
