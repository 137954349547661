import { Stack, Typography, Rating } from "@mui/material";

import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

const icons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon
        sx={(theme) => ({
          fontSize: theme.spacing(5),
        })}
      />
    ),
    label: "Dissatisfied",
  },
  2: {
    icon: (
      <SentimentSatisfiedIcon
        sx={(theme) => ({
          fontSize: theme.spacing(5),
        })}
      />
    ),
    label: "Neutral",
  },
  3: {
    icon: (
      <SentimentVerySatisfiedIcon
        sx={(theme) => ({
          fontSize: theme.spacing(5),
        })}
      />
    ),
    label: "Satisfied",
  },
};

function SentimentIconContainer(props) {
  const { value, ...other } = props;

  return <span {...other}>{icons[value].icon}</span>;
}

function SentimentWidget({ onHover, onVote, value, hover }) {
  const label = icons[hover !== -1 ? hover : value]?.label;

  return (
    <Stack spacing={2} alignItems="center">
      <Rating
        readOnly={!!value}
        value={value}
        IconContainerComponent={SentimentIconContainer}
        highlightSelectedOnly
        max={3}
        onChange={(_, _value) => {
          onVote(_value);
        }}
        onChangeActive={(_, _hover) => {
          onHover(_hover);
        }}
      />
      {label ? (
        <Typography sx={{ height: "1.2rem" }} variant="overline">
          Your Rating: <strong>{label}</strong>
        </Typography>
      ) : (
        <Typography sx={{ height: "1.2rem" }} variant="subtitle2">
          Please, choose rating.
        </Typography>
      )}
    </Stack>
  );
}

export default SentimentWidget;
