import { Stack, Typography, Chip, Rating, IconButton } from "@mui/material";

function NPSIconContainer(props) {
  const { value, className } = props;

  const icons = [];
  for (let i = 0; i <= 10; i++) {
    icons.push({
      icon: (
        <IconButton className={className}>
          <Chip size="large" label={i} className={className} />
        </IconButton>
      ),
      label: i.toString(),
    });
  }

  return icons[value].icon;
}

function NPSWidget({ onHover, onVote, value, hover }) {
  const labels = {
    1: "Not likely to recommend",
    2: "2 of 10",
    3: "3 of 10",
    4: "4 of 10",
    5: "5 of 10",
    6: "6 of 10",
    7: "7 of 10",
    8: "8 of 10",
    9: "9 of 10",
    10: "Extremely likely to recommend",
  };

  const label = labels[hover !== -1 ? hover : value];

  return (
    <Stack spacing={2} alignItems="center" sx={{ overflow: "hidden" }}>
      <Rating
        readOnly={!!value}
        value={value}
        sx={(theme) => ({
          gap: theme.spacing(0.5),
          [theme.breakpoints.down("sm")]: {
            transform: "scale(0.6)",
            transformOrigin: "center center",
          },
        })}
        IconContainerComponent={NPSIconContainer}
        max={10}
        onChange={(e, _value) => {
          onVote(_value);
        }}
        onChangeActive={(_, _hover) => {
          onHover(_hover);
        }}
      />
      {label ? (
        <Typography sx={{ height: "1.2rem" }} variant="overline">
          Your Rating: <strong>{label}</strong>
        </Typography>
      ) : (
        <Typography sx={{ height: "1.2rem" }} variant="subtitle2">
          Please, choose rating.
        </Typography>
      )}
    </Stack>
  );
}

export default NPSWidget;
