import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  Dashboard,
  Terminal,
  Monitor,
  HelpCenter,
  CreatePoll,
  Poll,
} from "./screens";
import { AppLayout } from "./components";
import { NotFound } from "../../../components";

function AppModule() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/terminal" element={<Terminal />} />
          <Route path="/monitor" element={<Monitor />} />
          <Route path="/help" element={<HelpCenter />} />
          <Route path="/create/poll" element={<CreatePoll />} />
          <Route path="/poll/:id" element={<Poll />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppModule;
