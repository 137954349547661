import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";

import { getLink } from "../../../../graphql/queries";
import { FullscreenLoader, NotFound } from "../../../../components";

function CloudRouterModule() {
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const linkID = pathname.substring(1);

  useEffect(() => {
    API.graphql({
      query: getLink,
      authMode: "AWS_IAM",
      variables: {
        id: linkID,
      },
    }).then(({ data: { getLink: _link } }) => {
      if (_link) {
        if (_link.subjectType === "POLL") {
          navigate(`/feedback/${_link.subjectId}`);
        }
      }
      setLoading(false);
    });

    return () => {
      setLoading(false);
    };
  }, [linkID, navigate]);

  if (loading) {
    return <FullscreenLoader caption="Navigating you to your content..." />;
  }

  return <NotFound />;
}

export default CloudRouterModule;
