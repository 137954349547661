import React, { useEffect, useContext } from "react";

import { ClientCreator, ClientSelector } from "./components";
import { ClientContext } from "../../../context";

function AppModule() {
  const {
    clients,
    setActive: setClient,
    active: activeClient,
    push: addClient,
  } = useContext(ClientContext);

  useEffect(() => {
    if (!activeClient) {
      if (clients.length === 1) {
        setClient(clients[0]);
      }
    }
  }, [clients, activeClient, setClient]);

  if (!activeClient) {
    if (clients.length > 1) {
      return (
        <ClientSelector
          clients={clients}
          onSelect={({ _client }) => {
            setClient(_client);
          }}
        />
      );
    }

    if (!clients.length) {
      return (
        <ClientCreator
          onCreate={(_client) => {
            addClient(_client);
            setClient(_client);
          }}
        />
      );
    }
  }

  // should never go here
  return (
    <div>
      Something went wrong. Please, try again or contact administrator. Error
      code 666
    </div>
  );
}

export default AppModule;
